<script setup>
definePageMeta({
  layout: 'minimal',
})

const backend = useBackend();
const route = useRoute();
const leasing = useLeasingParams();

const {data: car} = await useAsyncData(() => new Promise(async resolve => {
  const car = await backend.getCar(
    route.params.id
  );

  resolve(car);
}))
if(!car?.value.data.count) {
    navigateTo("/", {redirectCode: 301, replace: true});
  }
const brand = computed(() => car ? car.value.data.brandName : "");
const model = computed(() => car ? car.value.data.modelName : "");
const version = computed(() => car ? car.value.data.versionName : "");
const isUsed = computed(() => car ? car.value.data.state !== 'Nowy' : false);
const hasAdditionalEquipment = computed(() =>
  !!car?.value?.data?.additional_equipment && Object.keys(car.value.data.additional_equipment).length > 0
);

const additionalEquipments = computed(() => car?.value?.data?.additional_equipment || {});

const equipment = computed(() => {
  const rebuilt = {};
  if (!car.value.data.equipment) {
    return rebuilt;
  }

  car.value.data.equipment.forEach(eq => {
    if (!rebuilt[eq.category]) {
      rebuilt[eq.category] = [];
    }
    rebuilt[eq.category].push(eq.name);
  });

  return Object.fromEntries(
    Object.entries(rebuilt)
      .sort((a, b) => {
        if (a[0] === "Pozostałe") return 1;
        if (b[0] === "Pozostałe") return -1;
        if (a[0] < b[0]) return -1;
        if (a[0] > b[0]) return 1;
        return 0;
      })
  );
})

useHead({
  title: `${brand.value.toUpperCase()} ${model.value.toUpperCase()} leasing - Wybierz i sfinansuj online!`,
  meta: [
    {name: 'description', content: `${brand.value.toUpperCase()} ${model.value.toUpperCase()} w leasingu dla przedsiębiorców i konsumentów ✔️ Decyzja leasingowa w 15 min ✔️ Atrakcyjne rabaty ✔️ Sprawdź!`},
    {name: 'robots', content: 'noindex, follow'}
  ],
  link: [
    {rel: 'canonical', href: 'https://vehis.pl'+route.path}
  ]
});
const showOfferPopup = ref(false);
const popupTitle = ref('');
const openPopup = (title) => {
  popupTitle.value = title;
  showOfferPopup.value = true;
}
onMounted(() => {
  leasing.leasingDrawerOpened.value = true;
})

</script>

<template>
  <Container no-paddings>
    <LayoutColumns>
      <LayoutMainColumn noflex>
        <div class="relative">
          <div class="shadow-box -ml-4 -mr-4 sm:-mx-8 bg-gray-100 container-sized">
            <div class="bg-gradient-to-t from-30% from-white via-gray-200 via-40% to-white to-100%">
              <div class="px-6 sm:px-8">
                <CarOfferPreview
                  :header="`${brand} ${model}`"
                  :subheader="version"
                  :car="car.data"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="pt-10">
          <OfferHeader>Twój samochód</OfferHeader>
          <div class="mb-10">
            <OfferSpecs :group="car.data.brandName + ' ' + car.data.versionName" hide-border-bottom>
              <OfferSpec label="Stan samochodu"><span class="font-normal">{{ car.data.state }}</span></OfferSpec>
              <OfferSpec label="Rok produkcji"><span class="font-normal">{{ car.data.productionYear }}</span></OfferSpec>
              <OfferSpec v-if="car.data.mileage" label="Przebieg"><span class="font-normal">{{ car.data.mileage }} km</span></OfferSpec>
              <OfferSpec label="Zarejestrowany"><span class="font-normal">{{ car.data.is_registered ? 'Tak' : 'Nie'}}</span></OfferSpec>
              <OfferSpec v-if="car.data?.is_registered" label="Data pierwszej rejestracji"><span class="font-normal">{{ car.data.first_registration_date }}</span></OfferSpec>
              <template v-if="isUsed">
              <OfferSpec label="Numer VIN"><span class="font-normal text-base text-azure"><span class="cursor-pointer" @click="openPopup('Zostaw kontakt! Nasz doradca sprawdzi dla Ciebie numer VIN samochodu')">Zapytaj o VIN</span></span></OfferSpec>
              <OfferSpec label="Kraj pochodzenia"><span class="font-normal">Polska</span></OfferSpec>
              </template>
            </OfferSpecs>
            <div class="grid grid-cols-1 md:grid-cols-3py-4 mx-4 md:mx-8 border-b-gray-400 border-b pb-4">
              <span v-if="isUsed" class="font-normal text-base text-azure"><span class="cursor-pointer" @click="openPopup('Zostaw kontakt! Rzeczoznawca przygotuje dla Ciebie raport')">Pobierz raport rzeczoznawcy</span></span>
            </div>
            <OfferSpecs group="Finansowanie">
              <OfferSpec label="Forma finansowania"><span class="font-normal">leasing {{ leasing.getCurrentParams().client === 'consumer' ? 'konsumencki' : 'operacyjny' }}</span></OfferSpec>
              <OfferSpec label="Okres umowy"><span class="font-normal">{{ leasing.getCurrentParams().duration }} miesięcy</span></OfferSpec>
            </OfferSpecs>
          </div>
          <div v-if="hasAdditionalEquipment">
            <OfferHeader>Wyposażenie dodatkowe</OfferHeader>
            <div class="font-extralight space-y-0.5  print:columns-2 md:columns-2 py-4 mx-8">
                <div v-for="additionalEquipItem in additionalEquipments" :key="additionalEquipItem">
                  {{ additionalEquipItem }}
                </div>
            </div>
          </div>
          <OfferHeader>Wyposażenie <span v-if="hasAdditionalEquipment">podstawowe</span></OfferHeader>
          <div>
            <OfferEquipment v-for="(eqs, group) in equipment"
                            :group="group"
                            :equipment="eqs" />
          </div>

          <a id="specs"></a>
          <OfferHeader>Dane techniczne</OfferHeader>
          <div>
            <OfferSpecs group="Nadwozie">
              <OfferSpec label="Typ nadwozia">{{ car.data.bodyType }}</OfferSpec>
              <OfferSpec label="Liczba drzwi">{{ car.data.doors }}</OfferSpec>
              <OfferSpec label="Liczba siedzeń">{{ car.data.seats }}</OfferSpec>
            </OfferSpecs>

            <OfferSpecs group="Wymiary">
              <OfferSpec label="Długość">{{ car.data.length }} cm</OfferSpec>
              <OfferSpec label="Szerokość">{{ car.data.width }} cm</OfferSpec>
              <OfferSpec label="Wysokość">{{ car.data.height }} cm</OfferSpec>
            </OfferSpecs>

            <OfferSpecs group="Pojemność">
              <OfferSpec label="Standardowa pojemność bagażnika (VDA)">{{ car.data.trunkCapacityMin }}&nbsp;l</OfferSpec>
            </OfferSpecs>
          </div>
        </div>
      </LayoutMainColumn>
      <LayoutSideColumn>
        <CarOfferFloatingBox class="hidden lg:block" :car="car.data" :imageOnScroll="300" offer-view />

      </LayoutSideColumn>
    </LayoutColumns>

  </Container>
  <Teleport to="body">
    <PopupModal :openPopup="showOfferPopup" @update:openPopup="showOfferPopup = $event">
      <FormContact :title="popupTitle" :car="car.data" standalone no-container />
    </PopupModal>
  </Teleport>
</template>
